<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      No Access
    </alert-warning>

    <div v-else>
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ userEmail }}: Messages</h2>
        <router-link :to="backRoute" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="bg-concrete rounded-md shadow-sm mt-6 xl:mt-8">
        <div class="flex flex-col">
          <v-table :is-loading="state.isInitialLoading">
            <template #header>
              <v-table-header>Message</v-table-header>
              <v-table-header><span class="sr-only">Actions</span></v-table-header>
            </template>

            <v-table-row v-for="(message, index) in state.messages" :key="index" class=" hover:bg-gray-50">
              <v-table-col class="font-semibold" v-html="urlize(message)" />
              <v-table-col>
                <div class="text-right space-x-2 flex justify-end items-center">
                  <button type="button" @click.stop="deleteMessage(index)" class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none" title="Delete">
                    <span class="sr-only">delete</span>
                    <span class="material-icons-outlined">delete</span>
                  </button>
                </div>
              </v-table-col>
            </v-table-row>

            <v-table-row v-if="Object.keys(state.messages).length === 0">
              <v-table-col colspan="3">No Messages</v-table-col>
            </v-table-row>
          </v-table>
        </div>
      </div>

      <div class="mt-6 xl:mt-8">
        <form-layout
          @submit="create"
          title="Message"
          icon="announcement"
        >
          <div class="space-y-4">
            <errors :errors="state.errors" />
            <text-input
              type="text"
              label="Text"
              v-model="state.form.message"
              :required="true"
            />
          </div>
          <template #actions>
            <div class="text-right">
              <submit-button :is-loading="state.isSaving">Create</submit-button>
            </div>
          </template>
        </form-layout>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import { store, getStoreProfile } from '@/services/store.js'
import { urlize } from '@/helpers/utils.js'

import Layout from '@/layouts/Default.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import TextInput from '@/components/form/Text.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import SettingsNav from '@/components/settings/Nav'

import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'

export default {
  name: 'settings-users-message',

  components: {
    Layout,
    AlertWarning,
    FormLayout,
    TextInput,
    SubmitButton,
    Errors,
    SettingsNav,

    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
  },

  setup() {
    const route = useRoute()
    const backRoute = { name: 'SettingsUsersIndex' }
    const userEmail = route.params.id

    const state = reactive({
      hasAccess: true,
      errors: [],
      isInitialLoading: true,
      isSaving: false,
      form: {},
      messages: [],
    })

    const init = () => {
      if (store.profileLoaded) {
        if (!store.profileData.is_administrator) {
          state.isInitialLoading = false
          state.hasAccess = false
        }
        else {
          getUserMessages()
        }
      }
    }

    const create = async () => {
      state.errors = []
      state.isSaving = true

      try {
        await http.post(
          `users/${userEmail}/message`,
          {
            message: state.form.message
          }
        )

        state.form.message = ''
        getUserMessages()
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const deleteMessage = async (messageId) => {
      if (window.confirm(`Do you really want delete this message?`)) {
        try {
          await http.delete(`users/${userEmail}/message/${messageId}`)

          getUserMessages()
          notification.success('Success', 'Message Deleted')
        } catch (e) {
          notification.error('Error deleting message')
        }
      }
    }

    const getUserMessages = async () => {
      state.isInitialLoading = true
      try {
        const { data } = await http.get(`users/${userEmail}`)
        state.messages = data.messages
      } catch (e) {
        notification.error('Error loading current user messages')
      }
      state.isInitialLoading = false
    }

    getStoreProfile()
    
    init()
    watch(
      () => store.profileData.is_administrator,
      () => {
        init()
      }
    )

    return {
      state,
      create,
      deleteMessage,
      backRoute,
      userEmail,
      urlize,
    }
  }
}
</script>
